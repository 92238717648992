html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  font-size: 87.5%;
}
@media all and (max-width: 768px) {
  html {
    font-size: 81.25%;
  }
}

body {
  font-size: 14px !important;
  line-height: 1.85714286em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", 'Helvetica', 'Arial', sans-serif;
  color: #666666;
  font-weight: 400;
}

#search-input { background: none; }
.drawerFlex .ant-drawer-body { display: flex; }

.vjs-seek-to-live-control, .vjs-volume-panel {
  display: none !important;
}

:root {
  --primary: #ED6600;
}

::selection {
  background: #F5C537 !important;
}

@media(max-width: 576px) {
  .only-desktop {
    display: none !important;
  }
}

@import '~antd/dist/antd.less';
@import 'AntTheme.less';
